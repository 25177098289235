//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-40:_4980,_9552,_9476,_1923,_6356,_9188,_8952,_6431;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-40')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-40', "_4980,_9552,_9476,_1923,_6356,_9188,_8952,_6431");
        }
      }catch (ex) {
        console.error(ex);
      }